import { graphql, Link } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import FooterLandingPage from "../components/Footer/footerLandingPage"
import FooterFlourish from "../components/FooterFlourish/FooterFlourish.js"
import Header from "../components/header"
import Layout from "../components/layout.js"
import Seo from "../components/seo"
import { StoreContext } from "../store"
import marketo from "../utilities/marketo.js"
import sectionBlocks from "../utilities/sectionBlocks"
import videoLinkModal from "../utilities/videoCtaLinkModal"

const Resource = ({ data, location }) => {
  let resource = null
  let resourceTitle = ""
  let resourceContent = ""
  let resourceNodeType = ""
  let contentMarkup = ""

  const resourcesArray = Object.entries(data)
  const selectResource = resourcesArray.filter(item => item[1] !== null)

  resource = selectResource[0][1]
  resourceTitle = selectResource[0][1].title
  resourceContent = selectResource[0][1].content
  resourceNodeType = selectResource[0][1].nodeType

  contentMarkup = () => {
    return { __html: `${resourceContent}` }
  }

  const { viewPortWidth } = React.useContext(StoreContext)

  const [isHubspot, setIsHubspot] = React.useState(false)

  React.useEffect(() => {
    sectionBlocks()
    if (isHubspot) {
      marketo()
    }
    videoLinkModal()

    if (
      typeof window !== "undefined" &&
      window.document &&
      window.document.querySelector(".HubspotApiBlock")
    ) {
      setIsHubspot(true)
    }
  }, [isHubspot])

  return (
    <Layout>
      <Seo pageData={resource} location={location} />

      <div className="patterns-quizzes-template-default single single-patterns-quizzes postid-476 wp-embed-responsive is-resources">
        <div className="Page">
          <Header siteTitle={`GoodHire`} viewPortWidth={viewPortWidth} />

          <div className="Page-content">
            <section className="Body">
              <div className="Wrap">
                <p className="Resource-breadcrumb">
                  <Link
                    to="/resources/"
                    rel="noopener noreferrer"
                    className="BreadcrumbParent"
                  >
                    Resources
                  </Link>
                  <span className="BreadcrumbChild">
                    {resourceNodeType + "s"}
                  </span>
                </p>

                {isHubspot ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: resourceContent }}
                  ></div>
                ) : (
                  resourceContent && parse(resourceContent)
                )}
              </div>
              <FooterFlourish />
            </section>
          </div>

          <FooterLandingPage />
        </div>
      </div>
    </Layout>
  )
}

export default Resource

export const query = graphql`
  query ($id: String!) {
    wpEBook(id: { eq: $id }) {
      title
      content
      nodeType
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1232, placeholder: BLURRED)
            }
          }
          mediaDetails {
            height
            width
          }
        }
      }
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphSiteName
        opengraphType
        readingTime
      }
      richSnippetSchemaJson
    }
    wpGuide(id: { eq: $id }) {
      title
      content
      nodeType
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1232, placeholder: BLURRED)
            }
          }
          mediaDetails {
            height
            width
          }
        }
      }
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphSiteName
        opengraphType
        readingTime
      }
      richSnippetSchemaJson
    }
    wpInfographic(id: { eq: $id }) {
      title
      content
      nodeType
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1232, placeholder: BLURRED)
            }
          }
          mediaDetails {
            height
            width
          }
        }
      }
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphSiteName
        opengraphType
        readingTime
      }
      richSnippetSchemaJson
    }
    wpDataSheet(id: { eq: $id }) {
      title
      content
      nodeType
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1232, placeholder: BLURRED)
            }
          }
          mediaDetails {
            height
            width
          }
        }
      }
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphSiteName
        opengraphType
        readingTime
      }
      richSnippetSchemaJson
    }
    wpWebinar(id: { eq: $id }) {
      title
      content
      nodeType
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1232, placeholder: BLURRED)
            }
          }
          mediaDetails {
            height
            width
          }
        }
      }
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphModifiedTime
        opengraphSiteName
        opengraphType
        readingTime
      }
      richSnippetSchemaJson
    }
  }
`
