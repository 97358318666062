import * as React from "react"
import { StoreContext } from "../../store"

const FooterFlourish = () => {
  const { viewPortWidth } = React.useContext(StoreContext)

  return (
    <div className="FooterFlourish">
      <div className="FooterFlourish-wave-left">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="464"
            height="74"
            viewBox="0 0 464 74"
          >
            <path
              fill="#EFF7FC"
              d="M0.783,74.1590457 C75.4943695,71.3755897 86.1317497,6.44347537 120.001921,1.15104431 C120.245684,1.14014434 121.434772,1.04204461 123.165885,1.16888063 C124.708727,1.02916282 126.301113,1.00339926 127.962863,1.12329893 C158.202354,3.32112932 156.168023,30.3590012 193.686712,51.5049437 C218.90627,65.7185051 243.473813,70.2410018 279.901514,63.6802106 C328.020919,55.0137433 335.648916,42.4331957 359.162134,41.6166888 C360.126286,41.582998 361.051793,41.5740798 361.960454,41.5750707 C361.184574,41.5066982 360.722812,41.4888619 360.722812,41.4888619 C404.026412,39.8082846 396.075379,80.9309 519.180635,74.3284907"
              transform="translate(-1)"
            ></path>
          </svg>
        </div>
      </div>

      <div className="FooterFlourish-wave-right">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="917"
            height="148"
            viewBox="0 0 917 148"
          >
            <path
              fill="#EFF7FC"
              d="M0.783,147.83 C76.18,145.021 86.915,91.493 121.096,86.152 C121.342,86.141 122.542,86.042 124.289,86.17 C125.846,86.029 127.453,86.003 129.13,86.124 C159.647,88.342 157.594,103.628 195.457,124.968 C220.908,139.312 245.701,143.876 282.463,137.255 C331.024,128.509 339.722,110.813 363.451,109.989 C364.424,109.955 365.358,109.946 366.275,109.947 C365.492,109.878 365.026,109.86 365.026,109.86 C408.727,108.164 449.641,141.832 528.36,128.378 C591.664,117.559 632.263,67.478 632.781,66.835 C638.778,59.325 643.535,52.737 647.129,48.018 C669.385,18.79 690.806,-1.888 733.156,0.961 C791.942,4.915 801.532,60.616 847.429,110.027 C856.58,119.879 862.067,125.071 879.175,127.049 C891.023,128.418 901.225,149.479 931.543,147.829"
              transform="translate(-1)"
            ></path>
          </svg>
        </div>
      </div>

      <div className="FooterFlourish-fingerprint-one">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="151"
            height="150"
            viewBox="0 0 151 150"
          >
            <g fill="#d1dee7">
              <path d="M45.6 7.1C49.1 5.6 52.7 4.3 56.5 3.3 57.2 3.1 57.9 3 58.6 2.8 61.2 2.2 63.8 1.8 66.4 1.4 62.6 4.3 55 5.5 46.4 7 46.1 7 45.9 7.1 45.6 7.1zM77.6 6.5C72.7 15 37.4 11.4 25.1 21.8 23.4 23.2 21.7 24.3 20 25.3 22.8 22.3 25.8 19.5 29 17 32.8 15.3 39.4 13.6 53.9 11.7 76.4 8.7 77.1 6.9 76.2 2 76.1 1.6 76.1 1.2 76 .9 76.6.9 77.2.9 77.8.9 78.1 2.6 78.8 4.6 77.6 6.5z"></path>{" "}
              <path d="M87,3.8 C84.6,6.9 92.5,12.9 82.4,16.4 C77.6,18.1 69.8,18.9 61.5,19.8 C38.6,22.3 35.3,23.8 31.8,28.9 C26.9,35.9 19.6,36.7 9.9,39.4 C10.3,38.7 10.7,38 11.1,37.3 C19.1,35.1 25.5,33.9 29,29.8 C34.5,23.3 32.3,21.3 61.4,18.2 C86.2,15.6 87.1,13.8 85.4,8.6 C84.8,6.8 84.2,4.8 85.6,2.9 C85.9,2.5 86.3,2.2 86.7,1.9 C87.6,2 88.5,2.2 89.4,2.3 C88.4,2.6 87.5,3.1 87,3.8 Z"></path>{" "}
              <path d="M95.8,10.3 C94.3,13.2 100.5,18.2 94.5,21.7 C87.8,25.6 66.9,25.7 53.4,27.9 C41.7,29.7 41,32.3 40.1,35.2 C39.9,36.1 39.6,37 39,37.9 C35.1,44.8 19.8,44.8 5,49.6 C5.3,48.9 5.5,48.2 5.8,47.5 C20.1,43.1 34.1,42.9 37.4,37.1 C37.8,36.3 38.1,35.6 38.3,34.7 C39.5,30.7 41.1,28 53,26.1 C57.7,25.3 63.5,24.8 69,24.3 C96.1,21.9 96.4,19.9 94.5,14.8 C93.8,13.1 93.1,11.2 94.1,9.4 C95.1,7.6 97.8,6.4 101.6,5.5 C102.6,5.9 103.6,6.3 104.6,6.7 C100.1,7.4 96.7,8.6 95.8,10.3 Z"></path>{" "}
              <path d="M105.1,19.5 C105.9,22 106.8,24.2 105.6,26.2 C100.7,33.7 59.2,30.8 50.7,37 C48.9,38.3 48.9,39.7 49,41.4 C49,43.8 49,46.7 43.8,49 C38.4,51.4 28.9,52.4 20,54 C10,55.7 5.6,57.3 2.3,59 C2.5,58.2 2.6,57.5 2.8,56.7 C6.6,55 12.3,53.3 25.4,51.3 C45,48.2 47.1,46.6 47.1,41.4 C47.1,39.5 47.1,37.3 49.6,35.6 C58.3,29.3 100,31.5 104.1,25.3 C104.8,24.3 104.4,22.9 103.6,20.7 C101.6,14.7 105.6,12.4 113.5,11.1 C114.3,11.6 115.1,12.1 115.9,12.6 C105.5,14.1 103.9,15.9 105.1,19.5 Z"></path>{" "}
              <path d="M114.2,23.6 C113.1,28.1 123.2,34.7 97.5,37.4 L97.1,37.4 C77,39.5 61.8,39.3 58.4,43.7 C57.8,44.5 57.6,45.4 57.8,46.7 C57.9,47.1 57.9,47.4 58,47.7 C58.4,49.5 58.7,51.3 57.5,53 C55.8,55.4 51.3,57 42.6,58.3 C39.7,58.8 36.7,59.1 33.4,59.6 C3.4,63.4 8.1,66.5 0.6,71.3 C0.6,70.5 0.7,69.8 0.8,69 C6.6,64.7 4.4,61.5 33.2,57.8 C40.5,56.9 53.6,55.5 56.1,52 C56.9,50.9 56.6,49.6 56.3,48.1 C56.2,47.7 56.2,47.4 56.1,47 C55.8,45.4 56.1,43.9 57,42.6 C61.1,37.4 76.1,37.9 97.4,35.6 C115.3,33.7 114.1,30.9 113.1,27.3 C111.4,21.5 114.1,19.2 123,17.5 C123.6,18 124.2,18.5 124.8,19 C118.9,20 114.8,21.3 114.2,23.6 Z"></path>{" "}
              <path d="M123.8,33.3 C125,41.5 118,42.6 94,44.5 L90.2,44.8 C75.6,46 68.3,47.6 66.8,49.8 C66.2,50.7 66.5,51.8 67,53.5 C67.2,54.3 67.5,55.1 67.7,56.1 C68.4,60.6 63.6,62.1 61.6,62.7 C51.4,65.8 29.7,65.8 19.1,69.3 C8.4,72.7 15.3,77.6 0.7,82.5 C0.6,81.9 0.6,81.3 0.6,80.7 C14,76 7,71.3 18.4,67.6 C29.3,64.1 51,64 61,61 C64.9,59.8 66.3,58.5 65.9,56.4 C65.7,55.5 65.5,54.8 65.3,54 C64.8,52.3 64.2,50.4 65.3,48.8 C70.8,40.6 119.8,44.8 122.1,36.3 C122.3,35.4 122.2,34.5 122,33.5 C121.3,28.7 123.1,26.3 130.4,24.4 C130.9,24.9 131.3,25.4 131.8,25.9 C123.2,28 123.3,30.1 123.8,33.3 Z"></path>{" "}
              <path d="M121.3,48.5 C106.2,51.3 78.9,50.9 75.7,56.1 C75.1,57.1 75.6,58.4 76.2,59.9 C76.7,61.1 77.1,62.3 77.1,63.6 C77,69.2 67.4,70.3 50.4,71.9 C21.9,74.4 21.3,76.7 20.2,80.7 C18.6,86.7 16.1,88.6 1.8,91.6 C1.7,91 1.6,90.4 1.5,89.8 C10,88.1 16,86.5 17.7,82.8 C20.6,76.2 15.6,73.2 50.2,70.2 C63.1,69 75.3,67.9 75.3,63.6 C75.3,62.6 74.9,61.6 74.5,60.6 C73.8,58.9 73.1,57 74.2,55.3 C78,48.9 104.7,49.8 121,46.8 C138.7,43.4 124.7,37.4 136.9,32.2 C137.2,32.7 137.6,33.2 138,33.7 C126.9,38.3 139.9,44.9 121.3,48.5 Z"></path>{" "}
              <path d="M122,55.6 C118.2,56.1 114.1,56.5 110.1,56.9 C83.8,59.4 83.5,61.3 85.3,66.5 C86.2,68.9 87.4,72.3 82.3,74.5 C77.3,76.6 67.6,77.4 57.3,78.2 C53.3,78.5 49.1,78.8 45.4,79.3 C23.6,81.7 30.1,85.8 28.4,90.8 C26.7,96 16.9,97.3 3.7,99.3 C3.5,98.7 3.4,98.2 3.2,97.6 C15,95.8 25.3,94.3 26.7,90.2 C28.2,85.7 21.6,80.1 45.1,77.5 C58,76.1 74.9,75.6 81.5,72.8 C85.1,71.3 84.5,69.6 83.6,67 C80.7,59 86.1,57.3 109.9,55.1 C146.7,51.6 135.1,48.1 142.5,41.5 C142.8,42 143.1,42.6 143.3,43.1 C138.7,47.9 144.1,52.7 122,55.6 Z"></path>{" "}
              <path d="M126.5,62.1 C114.5,63.7 101.4,64.5 96.3,67.4 C93.5,69 94,70.6 94.6,72.8 C94.9,73.8 95.1,74.7 95.1,75.7 C95,86.1 58.5,82.5 42.4,87.2 C36.7,88.9 37.1,90.8 37.8,93.5 C38.1,94.8 38.4,96.2 38,97.6 C36.3,103.5 21.2,104.3 6.5,106.2 C6.2,105.6 6,105.1 5.8,104.5 C19.8,102.6 35,101.6 36.4,97 C37.4,93.7 31.8,88.4 42,85.4 C56.7,81.1 93.4,83.4 93.4,75.6 C93.4,72.9 90.1,68.7 95.5,65.7 C101,62.6 114.1,61.8 126.4,60.2 C141.8,58.2 144.7,55.6 147.4,52.6 C147.6,53.2 147.8,53.9 148,54.5 C145.2,57.5 141.4,60.1 126.5,62.1 Z"></path>{" "}
              <path d="M103.7,79.2 C103.8,80.2 103.9,81.2 103.7,82.3 C101.6,93.2 53.5,88.3 46.9,95.5 C45.9,96.6 46.3,97.9 46.9,99.8 C47.6,101.7 48.4,104 46.5,106 C42.1,110.7 24,111.1 9.8,113 C9.5,112.5 9.2,111.9 8.9,111.3 C23.2,109.3 41.4,108.9 45.2,104.8 C47.6,102.2 42.3,97.9 45.5,94.3 C52.5,86.5 100.2,90.8 101.9,82 C102.6,78.3 98,73.2 112.6,69.9 C125.5,67 139.5,67.1 149.6,62 C149.7,62.6 149.8,63.2 149.9,63.8 C143.2,67 135.1,68.1 126.6,69.3 C104.8,72.3 103.1,74.2 103.7,79.2 Z"></path>{" "}
              <path d="M112.6,85.9 C112.3,88 112,90.4 108.1,92.3 C96.8,97.7 59.3,95.7 55.6,102.2 C55,103.3 55.5,104.6 56.1,106.1 C56.8,107.8 57.5,109.7 56.5,111.4 C55.5,113.3 52.8,114.6 48.1,115.6 C38.3,117.7 24.3,117.9 13.8,119.5 C13.4,119 13.1,118.5 12.7,117.9 C28.5,115.2 52,115.9 54.9,110.6 C55.5,109.5 55,108.2 54.4,106.7 C53.7,105 52.9,103.1 54,101.2 C58.4,93.4 95.8,96.1 107.2,90.6 C113.5,87.5 107.9,82.9 115.2,78.9 C122.7,74.9 138.8,74.2 150.7,70 C150.7,70.6 150.8,71.2 150.8,71.8 C145.1,73.8 138.6,75 132.7,76.1 C115.3,79.4 113.3,81 112.6,85.9 Z"></path>{" "}
              <path d="M90,102.8 C63.7,105.4 63.5,107.4 65.4,112.4 C66.8,116.3 66.6,118.9 61.4,120.7 C56.1,122.6 45.1,123.5 34.2,124.4 C28.4,124.9 23.2,125.3 18.9,125.9 C18.5,125.4 18,124.9 17.6,124.3 C21.8,123.5 27.2,123.1 34.1,122.5 C39.3,122.1 45.3,121.6 51.2,120.9 C54.4,120.5 62.9,119.4 64.2,116.6 C65.3,114.3 61.7,110.8 63.4,107.5 C67.7,99.6 104.7,102.2 115.6,96.1 C122.4,92.3 115.7,87.5 133.8,82.8 C136,82.2 138.2,81.7 140.2,81.2 C143.8,80.4 147.3,79.5 151,78.3 C151,78.9 151,79.4 150.9,80 C150.9,80.1 150.9,80.1 150.9,80.2 C145.3,82 140.1,82.9 134.2,84.5 C108,91.5 137.4,98.1 90,102.8 Z"></path>{" "}
              <path d="M98.2,109 C90.1,110 81.8,110.9 77.4,112.8 C70.6,115.8 76.9,119.6 74.4,123.8 C73.4,125.4 71.3,126.6 67.7,127.5 C57.4,130.1 36.8,130.1 25.4,132.4 C24.9,131.9 24.3,131.4 23.8,130.9 C29.1,129.7 36.6,129.1 45.2,128.4 C57.9,127.4 70.7,126.4 72.8,122.8 C73.4,121.8 73.1,120.5 72.7,119.1 C70.7,111.2 76.1,109.7 97.9,107.1 C140.7,102 113.4,98.4 146.3,89 C147.8,88.6 149,88.2 150.1,87.9 C150,88.5 149.9,89.2 149.8,89.8 C148.9,90.1 148,90.3 146.9,90.6 C114.4,100.1 141.9,103.8 98.2,109 Z"></path>{" "}
              <path d="M53.6,134.6 C61.2,134 68.4,133.4 73.8,132.3 C82.2,130.5 82,128.2 81.8,125.4 C81.2,118 86.7,116.5 105,113.7 C139.7,108.5 125.6,105.6 147.6,98.8 C147.4,99.5 147.1,100.2 146.9,100.9 C128.7,107 136.2,110.4 112.4,114.3 C99.8,116.4 86.1,117.5 83.9,121.9 C82.2,125.4 87.8,131 74.2,133.9 C68.6,135.1 61.4,135.6 53.7,136.2 C49.3,136.6 39.9,137.1 33.3,138.6 C32.6,138.2 32,137.7 31.4,137.2 C36.8,135.9 44.8,135.3 53.6,134.6 Z"></path>{" "}
              <path d="M61.4,140.8 C87.5,138.6 89.7,136.8 90.7,131.6 C91,130 91.3,128.2 93.3,126.5 C99.6,121 119.2,120.7 131.3,115.7 C137.2,113.3 137.9,112 143.2,110 C142.8,110.8 142.4,111.6 141.9,112.5 C140.7,113.1 139.4,113.7 138,114.4 C136.2,115.4 134.3,116.4 132,117.4 C119.8,122.4 100.2,122.9 94.5,127.9 C91.5,130.6 93.6,133.9 90,137 C85.7,140.6 74,141.6 61.6,142.7 C55.2,143.2 48.7,143.8 43.8,144.7 C42.9,144.3 42.1,143.9 41.2,143.4 C45.6,142.3 52.2,141.6 61.4,140.8 Z"></path>{" "}
              <path d="M63.3 147.6C71.8 146.6 82.2 145.9 89 144.3 96.5 142.6 97.8 140.3 99.2 137.9 104.8 127.9 119.2 129.7 135.3 122.5 134.5 123.6 133.6 124.6 132.8 125.6 130.1 126.6 126.7 127.7 121.9 128.9 111.9 131.4 105.3 132.9 102.3 136.5 99.6 139.7 100.2 143.6 89.5 146.1 82.5 147.8 72.1 148.5 63.6 149.5 62.1 149.7 60.7 149.8 59.4 150 58 149.7 56.6 149.3 55.2 148.9 57.4 148.3 60.1 148 63.3 147.6zM120.2 137.1C115.8 140.3 111 143.1 105.9 145.3 108.2 143 109.7 140.6 120.2 137.1z"></path>
            </g>
          </svg>
        </div>
      </div>

      <div className="FooterFlourish-fingerprint-two">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="151"
            height="150"
            viewBox="0 0 151 150"
          >
            <g fill="#d1dee7">
              <path d="M45.6 7.1C49.1 5.6 52.7 4.3 56.5 3.3 57.2 3.1 57.9 3 58.6 2.8 61.2 2.2 63.8 1.8 66.4 1.4 62.6 4.3 55 5.5 46.4 7 46.1 7 45.9 7.1 45.6 7.1zM77.6 6.5C72.7 15 37.4 11.4 25.1 21.8 23.4 23.2 21.7 24.3 20 25.3 22.8 22.3 25.8 19.5 29 17 32.8 15.3 39.4 13.6 53.9 11.7 76.4 8.7 77.1 6.9 76.2 2 76.1 1.6 76.1 1.2 76 .9 76.6.9 77.2.9 77.8.9 78.1 2.6 78.8 4.6 77.6 6.5z"></path>{" "}
              <path d="M87,3.8 C84.6,6.9 92.5,12.9 82.4,16.4 C77.6,18.1 69.8,18.9 61.5,19.8 C38.6,22.3 35.3,23.8 31.8,28.9 C26.9,35.9 19.6,36.7 9.9,39.4 C10.3,38.7 10.7,38 11.1,37.3 C19.1,35.1 25.5,33.9 29,29.8 C34.5,23.3 32.3,21.3 61.4,18.2 C86.2,15.6 87.1,13.8 85.4,8.6 C84.8,6.8 84.2,4.8 85.6,2.9 C85.9,2.5 86.3,2.2 86.7,1.9 C87.6,2 88.5,2.2 89.4,2.3 C88.4,2.6 87.5,3.1 87,3.8 Z"></path>{" "}
              <path d="M95.8,10.3 C94.3,13.2 100.5,18.2 94.5,21.7 C87.8,25.6 66.9,25.7 53.4,27.9 C41.7,29.7 41,32.3 40.1,35.2 C39.9,36.1 39.6,37 39,37.9 C35.1,44.8 19.8,44.8 5,49.6 C5.3,48.9 5.5,48.2 5.8,47.5 C20.1,43.1 34.1,42.9 37.4,37.1 C37.8,36.3 38.1,35.6 38.3,34.7 C39.5,30.7 41.1,28 53,26.1 C57.7,25.3 63.5,24.8 69,24.3 C96.1,21.9 96.4,19.9 94.5,14.8 C93.8,13.1 93.1,11.2 94.1,9.4 C95.1,7.6 97.8,6.4 101.6,5.5 C102.6,5.9 103.6,6.3 104.6,6.7 C100.1,7.4 96.7,8.6 95.8,10.3 Z"></path>{" "}
              <path d="M105.1,19.5 C105.9,22 106.8,24.2 105.6,26.2 C100.7,33.7 59.2,30.8 50.7,37 C48.9,38.3 48.9,39.7 49,41.4 C49,43.8 49,46.7 43.8,49 C38.4,51.4 28.9,52.4 20,54 C10,55.7 5.6,57.3 2.3,59 C2.5,58.2 2.6,57.5 2.8,56.7 C6.6,55 12.3,53.3 25.4,51.3 C45,48.2 47.1,46.6 47.1,41.4 C47.1,39.5 47.1,37.3 49.6,35.6 C58.3,29.3 100,31.5 104.1,25.3 C104.8,24.3 104.4,22.9 103.6,20.7 C101.6,14.7 105.6,12.4 113.5,11.1 C114.3,11.6 115.1,12.1 115.9,12.6 C105.5,14.1 103.9,15.9 105.1,19.5 Z"></path>{" "}
              <path d="M114.2,23.6 C113.1,28.1 123.2,34.7 97.5,37.4 L97.1,37.4 C77,39.5 61.8,39.3 58.4,43.7 C57.8,44.5 57.6,45.4 57.8,46.7 C57.9,47.1 57.9,47.4 58,47.7 C58.4,49.5 58.7,51.3 57.5,53 C55.8,55.4 51.3,57 42.6,58.3 C39.7,58.8 36.7,59.1 33.4,59.6 C3.4,63.4 8.1,66.5 0.6,71.3 C0.6,70.5 0.7,69.8 0.8,69 C6.6,64.7 4.4,61.5 33.2,57.8 C40.5,56.9 53.6,55.5 56.1,52 C56.9,50.9 56.6,49.6 56.3,48.1 C56.2,47.7 56.2,47.4 56.1,47 C55.8,45.4 56.1,43.9 57,42.6 C61.1,37.4 76.1,37.9 97.4,35.6 C115.3,33.7 114.1,30.9 113.1,27.3 C111.4,21.5 114.1,19.2 123,17.5 C123.6,18 124.2,18.5 124.8,19 C118.9,20 114.8,21.3 114.2,23.6 Z"></path>{" "}
              <path d="M123.8,33.3 C125,41.5 118,42.6 94,44.5 L90.2,44.8 C75.6,46 68.3,47.6 66.8,49.8 C66.2,50.7 66.5,51.8 67,53.5 C67.2,54.3 67.5,55.1 67.7,56.1 C68.4,60.6 63.6,62.1 61.6,62.7 C51.4,65.8 29.7,65.8 19.1,69.3 C8.4,72.7 15.3,77.6 0.7,82.5 C0.6,81.9 0.6,81.3 0.6,80.7 C14,76 7,71.3 18.4,67.6 C29.3,64.1 51,64 61,61 C64.9,59.8 66.3,58.5 65.9,56.4 C65.7,55.5 65.5,54.8 65.3,54 C64.8,52.3 64.2,50.4 65.3,48.8 C70.8,40.6 119.8,44.8 122.1,36.3 C122.3,35.4 122.2,34.5 122,33.5 C121.3,28.7 123.1,26.3 130.4,24.4 C130.9,24.9 131.3,25.4 131.8,25.9 C123.2,28 123.3,30.1 123.8,33.3 Z"></path>{" "}
              <path d="M121.3,48.5 C106.2,51.3 78.9,50.9 75.7,56.1 C75.1,57.1 75.6,58.4 76.2,59.9 C76.7,61.1 77.1,62.3 77.1,63.6 C77,69.2 67.4,70.3 50.4,71.9 C21.9,74.4 21.3,76.7 20.2,80.7 C18.6,86.7 16.1,88.6 1.8,91.6 C1.7,91 1.6,90.4 1.5,89.8 C10,88.1 16,86.5 17.7,82.8 C20.6,76.2 15.6,73.2 50.2,70.2 C63.1,69 75.3,67.9 75.3,63.6 C75.3,62.6 74.9,61.6 74.5,60.6 C73.8,58.9 73.1,57 74.2,55.3 C78,48.9 104.7,49.8 121,46.8 C138.7,43.4 124.7,37.4 136.9,32.2 C137.2,32.7 137.6,33.2 138,33.7 C126.9,38.3 139.9,44.9 121.3,48.5 Z"></path>{" "}
              <path d="M122,55.6 C118.2,56.1 114.1,56.5 110.1,56.9 C83.8,59.4 83.5,61.3 85.3,66.5 C86.2,68.9 87.4,72.3 82.3,74.5 C77.3,76.6 67.6,77.4 57.3,78.2 C53.3,78.5 49.1,78.8 45.4,79.3 C23.6,81.7 30.1,85.8 28.4,90.8 C26.7,96 16.9,97.3 3.7,99.3 C3.5,98.7 3.4,98.2 3.2,97.6 C15,95.8 25.3,94.3 26.7,90.2 C28.2,85.7 21.6,80.1 45.1,77.5 C58,76.1 74.9,75.6 81.5,72.8 C85.1,71.3 84.5,69.6 83.6,67 C80.7,59 86.1,57.3 109.9,55.1 C146.7,51.6 135.1,48.1 142.5,41.5 C142.8,42 143.1,42.6 143.3,43.1 C138.7,47.9 144.1,52.7 122,55.6 Z"></path>{" "}
              <path d="M126.5,62.1 C114.5,63.7 101.4,64.5 96.3,67.4 C93.5,69 94,70.6 94.6,72.8 C94.9,73.8 95.1,74.7 95.1,75.7 C95,86.1 58.5,82.5 42.4,87.2 C36.7,88.9 37.1,90.8 37.8,93.5 C38.1,94.8 38.4,96.2 38,97.6 C36.3,103.5 21.2,104.3 6.5,106.2 C6.2,105.6 6,105.1 5.8,104.5 C19.8,102.6 35,101.6 36.4,97 C37.4,93.7 31.8,88.4 42,85.4 C56.7,81.1 93.4,83.4 93.4,75.6 C93.4,72.9 90.1,68.7 95.5,65.7 C101,62.6 114.1,61.8 126.4,60.2 C141.8,58.2 144.7,55.6 147.4,52.6 C147.6,53.2 147.8,53.9 148,54.5 C145.2,57.5 141.4,60.1 126.5,62.1 Z"></path>{" "}
              <path d="M103.7,79.2 C103.8,80.2 103.9,81.2 103.7,82.3 C101.6,93.2 53.5,88.3 46.9,95.5 C45.9,96.6 46.3,97.9 46.9,99.8 C47.6,101.7 48.4,104 46.5,106 C42.1,110.7 24,111.1 9.8,113 C9.5,112.5 9.2,111.9 8.9,111.3 C23.2,109.3 41.4,108.9 45.2,104.8 C47.6,102.2 42.3,97.9 45.5,94.3 C52.5,86.5 100.2,90.8 101.9,82 C102.6,78.3 98,73.2 112.6,69.9 C125.5,67 139.5,67.1 149.6,62 C149.7,62.6 149.8,63.2 149.9,63.8 C143.2,67 135.1,68.1 126.6,69.3 C104.8,72.3 103.1,74.2 103.7,79.2 Z"></path>{" "}
              <path d="M112.6,85.9 C112.3,88 112,90.4 108.1,92.3 C96.8,97.7 59.3,95.7 55.6,102.2 C55,103.3 55.5,104.6 56.1,106.1 C56.8,107.8 57.5,109.7 56.5,111.4 C55.5,113.3 52.8,114.6 48.1,115.6 C38.3,117.7 24.3,117.9 13.8,119.5 C13.4,119 13.1,118.5 12.7,117.9 C28.5,115.2 52,115.9 54.9,110.6 C55.5,109.5 55,108.2 54.4,106.7 C53.7,105 52.9,103.1 54,101.2 C58.4,93.4 95.8,96.1 107.2,90.6 C113.5,87.5 107.9,82.9 115.2,78.9 C122.7,74.9 138.8,74.2 150.7,70 C150.7,70.6 150.8,71.2 150.8,71.8 C145.1,73.8 138.6,75 132.7,76.1 C115.3,79.4 113.3,81 112.6,85.9 Z"></path>{" "}
              <path d="M90,102.8 C63.7,105.4 63.5,107.4 65.4,112.4 C66.8,116.3 66.6,118.9 61.4,120.7 C56.1,122.6 45.1,123.5 34.2,124.4 C28.4,124.9 23.2,125.3 18.9,125.9 C18.5,125.4 18,124.9 17.6,124.3 C21.8,123.5 27.2,123.1 34.1,122.5 C39.3,122.1 45.3,121.6 51.2,120.9 C54.4,120.5 62.9,119.4 64.2,116.6 C65.3,114.3 61.7,110.8 63.4,107.5 C67.7,99.6 104.7,102.2 115.6,96.1 C122.4,92.3 115.7,87.5 133.8,82.8 C136,82.2 138.2,81.7 140.2,81.2 C143.8,80.4 147.3,79.5 151,78.3 C151,78.9 151,79.4 150.9,80 C150.9,80.1 150.9,80.1 150.9,80.2 C145.3,82 140.1,82.9 134.2,84.5 C108,91.5 137.4,98.1 90,102.8 Z"></path>{" "}
              <path d="M98.2,109 C90.1,110 81.8,110.9 77.4,112.8 C70.6,115.8 76.9,119.6 74.4,123.8 C73.4,125.4 71.3,126.6 67.7,127.5 C57.4,130.1 36.8,130.1 25.4,132.4 C24.9,131.9 24.3,131.4 23.8,130.9 C29.1,129.7 36.6,129.1 45.2,128.4 C57.9,127.4 70.7,126.4 72.8,122.8 C73.4,121.8 73.1,120.5 72.7,119.1 C70.7,111.2 76.1,109.7 97.9,107.1 C140.7,102 113.4,98.4 146.3,89 C147.8,88.6 149,88.2 150.1,87.9 C150,88.5 149.9,89.2 149.8,89.8 C148.9,90.1 148,90.3 146.9,90.6 C114.4,100.1 141.9,103.8 98.2,109 Z"></path>{" "}
              <path d="M53.6,134.6 C61.2,134 68.4,133.4 73.8,132.3 C82.2,130.5 82,128.2 81.8,125.4 C81.2,118 86.7,116.5 105,113.7 C139.7,108.5 125.6,105.6 147.6,98.8 C147.4,99.5 147.1,100.2 146.9,100.9 C128.7,107 136.2,110.4 112.4,114.3 C99.8,116.4 86.1,117.5 83.9,121.9 C82.2,125.4 87.8,131 74.2,133.9 C68.6,135.1 61.4,135.6 53.7,136.2 C49.3,136.6 39.9,137.1 33.3,138.6 C32.6,138.2 32,137.7 31.4,137.2 C36.8,135.9 44.8,135.3 53.6,134.6 Z"></path>{" "}
              <path d="M61.4,140.8 C87.5,138.6 89.7,136.8 90.7,131.6 C91,130 91.3,128.2 93.3,126.5 C99.6,121 119.2,120.7 131.3,115.7 C137.2,113.3 137.9,112 143.2,110 C142.8,110.8 142.4,111.6 141.9,112.5 C140.7,113.1 139.4,113.7 138,114.4 C136.2,115.4 134.3,116.4 132,117.4 C119.8,122.4 100.2,122.9 94.5,127.9 C91.5,130.6 93.6,133.9 90,137 C85.7,140.6 74,141.6 61.6,142.7 C55.2,143.2 48.7,143.8 43.8,144.7 C42.9,144.3 42.1,143.9 41.2,143.4 C45.6,142.3 52.2,141.6 61.4,140.8 Z"></path>{" "}
              <path d="M63.3 147.6C71.8 146.6 82.2 145.9 89 144.3 96.5 142.6 97.8 140.3 99.2 137.9 104.8 127.9 119.2 129.7 135.3 122.5 134.5 123.6 133.6 124.6 132.8 125.6 130.1 126.6 126.7 127.7 121.9 128.9 111.9 131.4 105.3 132.9 102.3 136.5 99.6 139.7 100.2 143.6 89.5 146.1 82.5 147.8 72.1 148.5 63.6 149.5 62.1 149.7 60.7 149.8 59.4 150 58 149.7 56.6 149.3 55.2 148.9 57.4 148.3 60.1 148 63.3 147.6zM120.2 137.1C115.8 140.3 111 143.1 105.9 145.3 108.2 143 109.7 140.6 120.2 137.1z"></path>
            </g>
          </svg>
        </div>
      </div>

      <div className="FooterFlourish-fingerprint-three">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="151"
            height="150"
            viewBox="0 0 151 150"
          >
            <g fill="#d1dee7">
              <path d="M45.6 7.1C49.1 5.6 52.7 4.3 56.5 3.3 57.2 3.1 57.9 3 58.6 2.8 61.2 2.2 63.8 1.8 66.4 1.4 62.6 4.3 55 5.5 46.4 7 46.1 7 45.9 7.1 45.6 7.1zM77.6 6.5C72.7 15 37.4 11.4 25.1 21.8 23.4 23.2 21.7 24.3 20 25.3 22.8 22.3 25.8 19.5 29 17 32.8 15.3 39.4 13.6 53.9 11.7 76.4 8.7 77.1 6.9 76.2 2 76.1 1.6 76.1 1.2 76 .9 76.6.9 77.2.9 77.8.9 78.1 2.6 78.8 4.6 77.6 6.5z"></path>{" "}
              <path d="M87,3.8 C84.6,6.9 92.5,12.9 82.4,16.4 C77.6,18.1 69.8,18.9 61.5,19.8 C38.6,22.3 35.3,23.8 31.8,28.9 C26.9,35.9 19.6,36.7 9.9,39.4 C10.3,38.7 10.7,38 11.1,37.3 C19.1,35.1 25.5,33.9 29,29.8 C34.5,23.3 32.3,21.3 61.4,18.2 C86.2,15.6 87.1,13.8 85.4,8.6 C84.8,6.8 84.2,4.8 85.6,2.9 C85.9,2.5 86.3,2.2 86.7,1.9 C87.6,2 88.5,2.2 89.4,2.3 C88.4,2.6 87.5,3.1 87,3.8 Z"></path>{" "}
              <path d="M95.8,10.3 C94.3,13.2 100.5,18.2 94.5,21.7 C87.8,25.6 66.9,25.7 53.4,27.9 C41.7,29.7 41,32.3 40.1,35.2 C39.9,36.1 39.6,37 39,37.9 C35.1,44.8 19.8,44.8 5,49.6 C5.3,48.9 5.5,48.2 5.8,47.5 C20.1,43.1 34.1,42.9 37.4,37.1 C37.8,36.3 38.1,35.6 38.3,34.7 C39.5,30.7 41.1,28 53,26.1 C57.7,25.3 63.5,24.8 69,24.3 C96.1,21.9 96.4,19.9 94.5,14.8 C93.8,13.1 93.1,11.2 94.1,9.4 C95.1,7.6 97.8,6.4 101.6,5.5 C102.6,5.9 103.6,6.3 104.6,6.7 C100.1,7.4 96.7,8.6 95.8,10.3 Z"></path>{" "}
              <path d="M105.1,19.5 C105.9,22 106.8,24.2 105.6,26.2 C100.7,33.7 59.2,30.8 50.7,37 C48.9,38.3 48.9,39.7 49,41.4 C49,43.8 49,46.7 43.8,49 C38.4,51.4 28.9,52.4 20,54 C10,55.7 5.6,57.3 2.3,59 C2.5,58.2 2.6,57.5 2.8,56.7 C6.6,55 12.3,53.3 25.4,51.3 C45,48.2 47.1,46.6 47.1,41.4 C47.1,39.5 47.1,37.3 49.6,35.6 C58.3,29.3 100,31.5 104.1,25.3 C104.8,24.3 104.4,22.9 103.6,20.7 C101.6,14.7 105.6,12.4 113.5,11.1 C114.3,11.6 115.1,12.1 115.9,12.6 C105.5,14.1 103.9,15.9 105.1,19.5 Z"></path>{" "}
              <path d="M114.2,23.6 C113.1,28.1 123.2,34.7 97.5,37.4 L97.1,37.4 C77,39.5 61.8,39.3 58.4,43.7 C57.8,44.5 57.6,45.4 57.8,46.7 C57.9,47.1 57.9,47.4 58,47.7 C58.4,49.5 58.7,51.3 57.5,53 C55.8,55.4 51.3,57 42.6,58.3 C39.7,58.8 36.7,59.1 33.4,59.6 C3.4,63.4 8.1,66.5 0.6,71.3 C0.6,70.5 0.7,69.8 0.8,69 C6.6,64.7 4.4,61.5 33.2,57.8 C40.5,56.9 53.6,55.5 56.1,52 C56.9,50.9 56.6,49.6 56.3,48.1 C56.2,47.7 56.2,47.4 56.1,47 C55.8,45.4 56.1,43.9 57,42.6 C61.1,37.4 76.1,37.9 97.4,35.6 C115.3,33.7 114.1,30.9 113.1,27.3 C111.4,21.5 114.1,19.2 123,17.5 C123.6,18 124.2,18.5 124.8,19 C118.9,20 114.8,21.3 114.2,23.6 Z"></path>{" "}
              <path d="M123.8,33.3 C125,41.5 118,42.6 94,44.5 L90.2,44.8 C75.6,46 68.3,47.6 66.8,49.8 C66.2,50.7 66.5,51.8 67,53.5 C67.2,54.3 67.5,55.1 67.7,56.1 C68.4,60.6 63.6,62.1 61.6,62.7 C51.4,65.8 29.7,65.8 19.1,69.3 C8.4,72.7 15.3,77.6 0.7,82.5 C0.6,81.9 0.6,81.3 0.6,80.7 C14,76 7,71.3 18.4,67.6 C29.3,64.1 51,64 61,61 C64.9,59.8 66.3,58.5 65.9,56.4 C65.7,55.5 65.5,54.8 65.3,54 C64.8,52.3 64.2,50.4 65.3,48.8 C70.8,40.6 119.8,44.8 122.1,36.3 C122.3,35.4 122.2,34.5 122,33.5 C121.3,28.7 123.1,26.3 130.4,24.4 C130.9,24.9 131.3,25.4 131.8,25.9 C123.2,28 123.3,30.1 123.8,33.3 Z"></path>{" "}
              <path d="M121.3,48.5 C106.2,51.3 78.9,50.9 75.7,56.1 C75.1,57.1 75.6,58.4 76.2,59.9 C76.7,61.1 77.1,62.3 77.1,63.6 C77,69.2 67.4,70.3 50.4,71.9 C21.9,74.4 21.3,76.7 20.2,80.7 C18.6,86.7 16.1,88.6 1.8,91.6 C1.7,91 1.6,90.4 1.5,89.8 C10,88.1 16,86.5 17.7,82.8 C20.6,76.2 15.6,73.2 50.2,70.2 C63.1,69 75.3,67.9 75.3,63.6 C75.3,62.6 74.9,61.6 74.5,60.6 C73.8,58.9 73.1,57 74.2,55.3 C78,48.9 104.7,49.8 121,46.8 C138.7,43.4 124.7,37.4 136.9,32.2 C137.2,32.7 137.6,33.2 138,33.7 C126.9,38.3 139.9,44.9 121.3,48.5 Z"></path>{" "}
              <path d="M122,55.6 C118.2,56.1 114.1,56.5 110.1,56.9 C83.8,59.4 83.5,61.3 85.3,66.5 C86.2,68.9 87.4,72.3 82.3,74.5 C77.3,76.6 67.6,77.4 57.3,78.2 C53.3,78.5 49.1,78.8 45.4,79.3 C23.6,81.7 30.1,85.8 28.4,90.8 C26.7,96 16.9,97.3 3.7,99.3 C3.5,98.7 3.4,98.2 3.2,97.6 C15,95.8 25.3,94.3 26.7,90.2 C28.2,85.7 21.6,80.1 45.1,77.5 C58,76.1 74.9,75.6 81.5,72.8 C85.1,71.3 84.5,69.6 83.6,67 C80.7,59 86.1,57.3 109.9,55.1 C146.7,51.6 135.1,48.1 142.5,41.5 C142.8,42 143.1,42.6 143.3,43.1 C138.7,47.9 144.1,52.7 122,55.6 Z"></path>{" "}
              <path d="M126.5,62.1 C114.5,63.7 101.4,64.5 96.3,67.4 C93.5,69 94,70.6 94.6,72.8 C94.9,73.8 95.1,74.7 95.1,75.7 C95,86.1 58.5,82.5 42.4,87.2 C36.7,88.9 37.1,90.8 37.8,93.5 C38.1,94.8 38.4,96.2 38,97.6 C36.3,103.5 21.2,104.3 6.5,106.2 C6.2,105.6 6,105.1 5.8,104.5 C19.8,102.6 35,101.6 36.4,97 C37.4,93.7 31.8,88.4 42,85.4 C56.7,81.1 93.4,83.4 93.4,75.6 C93.4,72.9 90.1,68.7 95.5,65.7 C101,62.6 114.1,61.8 126.4,60.2 C141.8,58.2 144.7,55.6 147.4,52.6 C147.6,53.2 147.8,53.9 148,54.5 C145.2,57.5 141.4,60.1 126.5,62.1 Z"></path>{" "}
              <path d="M103.7,79.2 C103.8,80.2 103.9,81.2 103.7,82.3 C101.6,93.2 53.5,88.3 46.9,95.5 C45.9,96.6 46.3,97.9 46.9,99.8 C47.6,101.7 48.4,104 46.5,106 C42.1,110.7 24,111.1 9.8,113 C9.5,112.5 9.2,111.9 8.9,111.3 C23.2,109.3 41.4,108.9 45.2,104.8 C47.6,102.2 42.3,97.9 45.5,94.3 C52.5,86.5 100.2,90.8 101.9,82 C102.6,78.3 98,73.2 112.6,69.9 C125.5,67 139.5,67.1 149.6,62 C149.7,62.6 149.8,63.2 149.9,63.8 C143.2,67 135.1,68.1 126.6,69.3 C104.8,72.3 103.1,74.2 103.7,79.2 Z"></path>{" "}
              <path d="M112.6,85.9 C112.3,88 112,90.4 108.1,92.3 C96.8,97.7 59.3,95.7 55.6,102.2 C55,103.3 55.5,104.6 56.1,106.1 C56.8,107.8 57.5,109.7 56.5,111.4 C55.5,113.3 52.8,114.6 48.1,115.6 C38.3,117.7 24.3,117.9 13.8,119.5 C13.4,119 13.1,118.5 12.7,117.9 C28.5,115.2 52,115.9 54.9,110.6 C55.5,109.5 55,108.2 54.4,106.7 C53.7,105 52.9,103.1 54,101.2 C58.4,93.4 95.8,96.1 107.2,90.6 C113.5,87.5 107.9,82.9 115.2,78.9 C122.7,74.9 138.8,74.2 150.7,70 C150.7,70.6 150.8,71.2 150.8,71.8 C145.1,73.8 138.6,75 132.7,76.1 C115.3,79.4 113.3,81 112.6,85.9 Z"></path>{" "}
              <path d="M90,102.8 C63.7,105.4 63.5,107.4 65.4,112.4 C66.8,116.3 66.6,118.9 61.4,120.7 C56.1,122.6 45.1,123.5 34.2,124.4 C28.4,124.9 23.2,125.3 18.9,125.9 C18.5,125.4 18,124.9 17.6,124.3 C21.8,123.5 27.2,123.1 34.1,122.5 C39.3,122.1 45.3,121.6 51.2,120.9 C54.4,120.5 62.9,119.4 64.2,116.6 C65.3,114.3 61.7,110.8 63.4,107.5 C67.7,99.6 104.7,102.2 115.6,96.1 C122.4,92.3 115.7,87.5 133.8,82.8 C136,82.2 138.2,81.7 140.2,81.2 C143.8,80.4 147.3,79.5 151,78.3 C151,78.9 151,79.4 150.9,80 C150.9,80.1 150.9,80.1 150.9,80.2 C145.3,82 140.1,82.9 134.2,84.5 C108,91.5 137.4,98.1 90,102.8 Z"></path>{" "}
              <path d="M98.2,109 C90.1,110 81.8,110.9 77.4,112.8 C70.6,115.8 76.9,119.6 74.4,123.8 C73.4,125.4 71.3,126.6 67.7,127.5 C57.4,130.1 36.8,130.1 25.4,132.4 C24.9,131.9 24.3,131.4 23.8,130.9 C29.1,129.7 36.6,129.1 45.2,128.4 C57.9,127.4 70.7,126.4 72.8,122.8 C73.4,121.8 73.1,120.5 72.7,119.1 C70.7,111.2 76.1,109.7 97.9,107.1 C140.7,102 113.4,98.4 146.3,89 C147.8,88.6 149,88.2 150.1,87.9 C150,88.5 149.9,89.2 149.8,89.8 C148.9,90.1 148,90.3 146.9,90.6 C114.4,100.1 141.9,103.8 98.2,109 Z"></path>{" "}
              <path d="M53.6,134.6 C61.2,134 68.4,133.4 73.8,132.3 C82.2,130.5 82,128.2 81.8,125.4 C81.2,118 86.7,116.5 105,113.7 C139.7,108.5 125.6,105.6 147.6,98.8 C147.4,99.5 147.1,100.2 146.9,100.9 C128.7,107 136.2,110.4 112.4,114.3 C99.8,116.4 86.1,117.5 83.9,121.9 C82.2,125.4 87.8,131 74.2,133.9 C68.6,135.1 61.4,135.6 53.7,136.2 C49.3,136.6 39.9,137.1 33.3,138.6 C32.6,138.2 32,137.7 31.4,137.2 C36.8,135.9 44.8,135.3 53.6,134.6 Z"></path>{" "}
              <path d="M61.4,140.8 C87.5,138.6 89.7,136.8 90.7,131.6 C91,130 91.3,128.2 93.3,126.5 C99.6,121 119.2,120.7 131.3,115.7 C137.2,113.3 137.9,112 143.2,110 C142.8,110.8 142.4,111.6 141.9,112.5 C140.7,113.1 139.4,113.7 138,114.4 C136.2,115.4 134.3,116.4 132,117.4 C119.8,122.4 100.2,122.9 94.5,127.9 C91.5,130.6 93.6,133.9 90,137 C85.7,140.6 74,141.6 61.6,142.7 C55.2,143.2 48.7,143.8 43.8,144.7 C42.9,144.3 42.1,143.9 41.2,143.4 C45.6,142.3 52.2,141.6 61.4,140.8 Z"></path>{" "}
              <path d="M63.3 147.6C71.8 146.6 82.2 145.9 89 144.3 96.5 142.6 97.8 140.3 99.2 137.9 104.8 127.9 119.2 129.7 135.3 122.5 134.5 123.6 133.6 124.6 132.8 125.6 130.1 126.6 126.7 127.7 121.9 128.9 111.9 131.4 105.3 132.9 102.3 136.5 99.6 139.7 100.2 143.6 89.5 146.1 82.5 147.8 72.1 148.5 63.6 149.5 62.1 149.7 60.7 149.8 59.4 150 58 149.7 56.6 149.3 55.2 148.9 57.4 148.3 60.1 148 63.3 147.6zM120.2 137.1C115.8 140.3 111 143.1 105.9 145.3 108.2 143 109.7 140.6 120.2 137.1z"></path>
            </g>
          </svg>
        </div>
      </div>

      <div className="FooterFlourish-fingerprint-four">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="151"
            height="150"
            viewBox="0 0 151 150"
          >
            <g fill="#d1dee7">
              <path d="M45.6 7.1C49.1 5.6 52.7 4.3 56.5 3.3 57.2 3.1 57.9 3 58.6 2.8 61.2 2.2 63.8 1.8 66.4 1.4 62.6 4.3 55 5.5 46.4 7 46.1 7 45.9 7.1 45.6 7.1zM77.6 6.5C72.7 15 37.4 11.4 25.1 21.8 23.4 23.2 21.7 24.3 20 25.3 22.8 22.3 25.8 19.5 29 17 32.8 15.3 39.4 13.6 53.9 11.7 76.4 8.7 77.1 6.9 76.2 2 76.1 1.6 76.1 1.2 76 .9 76.6.9 77.2.9 77.8.9 78.1 2.6 78.8 4.6 77.6 6.5z"></path>{" "}
              <path d="M87,3.8 C84.6,6.9 92.5,12.9 82.4,16.4 C77.6,18.1 69.8,18.9 61.5,19.8 C38.6,22.3 35.3,23.8 31.8,28.9 C26.9,35.9 19.6,36.7 9.9,39.4 C10.3,38.7 10.7,38 11.1,37.3 C19.1,35.1 25.5,33.9 29,29.8 C34.5,23.3 32.3,21.3 61.4,18.2 C86.2,15.6 87.1,13.8 85.4,8.6 C84.8,6.8 84.2,4.8 85.6,2.9 C85.9,2.5 86.3,2.2 86.7,1.9 C87.6,2 88.5,2.2 89.4,2.3 C88.4,2.6 87.5,3.1 87,3.8 Z"></path>{" "}
              <path d="M95.8,10.3 C94.3,13.2 100.5,18.2 94.5,21.7 C87.8,25.6 66.9,25.7 53.4,27.9 C41.7,29.7 41,32.3 40.1,35.2 C39.9,36.1 39.6,37 39,37.9 C35.1,44.8 19.8,44.8 5,49.6 C5.3,48.9 5.5,48.2 5.8,47.5 C20.1,43.1 34.1,42.9 37.4,37.1 C37.8,36.3 38.1,35.6 38.3,34.7 C39.5,30.7 41.1,28 53,26.1 C57.7,25.3 63.5,24.8 69,24.3 C96.1,21.9 96.4,19.9 94.5,14.8 C93.8,13.1 93.1,11.2 94.1,9.4 C95.1,7.6 97.8,6.4 101.6,5.5 C102.6,5.9 103.6,6.3 104.6,6.7 C100.1,7.4 96.7,8.6 95.8,10.3 Z"></path>{" "}
              <path d="M105.1,19.5 C105.9,22 106.8,24.2 105.6,26.2 C100.7,33.7 59.2,30.8 50.7,37 C48.9,38.3 48.9,39.7 49,41.4 C49,43.8 49,46.7 43.8,49 C38.4,51.4 28.9,52.4 20,54 C10,55.7 5.6,57.3 2.3,59 C2.5,58.2 2.6,57.5 2.8,56.7 C6.6,55 12.3,53.3 25.4,51.3 C45,48.2 47.1,46.6 47.1,41.4 C47.1,39.5 47.1,37.3 49.6,35.6 C58.3,29.3 100,31.5 104.1,25.3 C104.8,24.3 104.4,22.9 103.6,20.7 C101.6,14.7 105.6,12.4 113.5,11.1 C114.3,11.6 115.1,12.1 115.9,12.6 C105.5,14.1 103.9,15.9 105.1,19.5 Z"></path>{" "}
              <path d="M114.2,23.6 C113.1,28.1 123.2,34.7 97.5,37.4 L97.1,37.4 C77,39.5 61.8,39.3 58.4,43.7 C57.8,44.5 57.6,45.4 57.8,46.7 C57.9,47.1 57.9,47.4 58,47.7 C58.4,49.5 58.7,51.3 57.5,53 C55.8,55.4 51.3,57 42.6,58.3 C39.7,58.8 36.7,59.1 33.4,59.6 C3.4,63.4 8.1,66.5 0.6,71.3 C0.6,70.5 0.7,69.8 0.8,69 C6.6,64.7 4.4,61.5 33.2,57.8 C40.5,56.9 53.6,55.5 56.1,52 C56.9,50.9 56.6,49.6 56.3,48.1 C56.2,47.7 56.2,47.4 56.1,47 C55.8,45.4 56.1,43.9 57,42.6 C61.1,37.4 76.1,37.9 97.4,35.6 C115.3,33.7 114.1,30.9 113.1,27.3 C111.4,21.5 114.1,19.2 123,17.5 C123.6,18 124.2,18.5 124.8,19 C118.9,20 114.8,21.3 114.2,23.6 Z"></path>{" "}
              <path d="M123.8,33.3 C125,41.5 118,42.6 94,44.5 L90.2,44.8 C75.6,46 68.3,47.6 66.8,49.8 C66.2,50.7 66.5,51.8 67,53.5 C67.2,54.3 67.5,55.1 67.7,56.1 C68.4,60.6 63.6,62.1 61.6,62.7 C51.4,65.8 29.7,65.8 19.1,69.3 C8.4,72.7 15.3,77.6 0.7,82.5 C0.6,81.9 0.6,81.3 0.6,80.7 C14,76 7,71.3 18.4,67.6 C29.3,64.1 51,64 61,61 C64.9,59.8 66.3,58.5 65.9,56.4 C65.7,55.5 65.5,54.8 65.3,54 C64.8,52.3 64.2,50.4 65.3,48.8 C70.8,40.6 119.8,44.8 122.1,36.3 C122.3,35.4 122.2,34.5 122,33.5 C121.3,28.7 123.1,26.3 130.4,24.4 C130.9,24.9 131.3,25.4 131.8,25.9 C123.2,28 123.3,30.1 123.8,33.3 Z"></path>{" "}
              <path d="M121.3,48.5 C106.2,51.3 78.9,50.9 75.7,56.1 C75.1,57.1 75.6,58.4 76.2,59.9 C76.7,61.1 77.1,62.3 77.1,63.6 C77,69.2 67.4,70.3 50.4,71.9 C21.9,74.4 21.3,76.7 20.2,80.7 C18.6,86.7 16.1,88.6 1.8,91.6 C1.7,91 1.6,90.4 1.5,89.8 C10,88.1 16,86.5 17.7,82.8 C20.6,76.2 15.6,73.2 50.2,70.2 C63.1,69 75.3,67.9 75.3,63.6 C75.3,62.6 74.9,61.6 74.5,60.6 C73.8,58.9 73.1,57 74.2,55.3 C78,48.9 104.7,49.8 121,46.8 C138.7,43.4 124.7,37.4 136.9,32.2 C137.2,32.7 137.6,33.2 138,33.7 C126.9,38.3 139.9,44.9 121.3,48.5 Z"></path>{" "}
              <path d="M122,55.6 C118.2,56.1 114.1,56.5 110.1,56.9 C83.8,59.4 83.5,61.3 85.3,66.5 C86.2,68.9 87.4,72.3 82.3,74.5 C77.3,76.6 67.6,77.4 57.3,78.2 C53.3,78.5 49.1,78.8 45.4,79.3 C23.6,81.7 30.1,85.8 28.4,90.8 C26.7,96 16.9,97.3 3.7,99.3 C3.5,98.7 3.4,98.2 3.2,97.6 C15,95.8 25.3,94.3 26.7,90.2 C28.2,85.7 21.6,80.1 45.1,77.5 C58,76.1 74.9,75.6 81.5,72.8 C85.1,71.3 84.5,69.6 83.6,67 C80.7,59 86.1,57.3 109.9,55.1 C146.7,51.6 135.1,48.1 142.5,41.5 C142.8,42 143.1,42.6 143.3,43.1 C138.7,47.9 144.1,52.7 122,55.6 Z"></path>{" "}
              <path d="M126.5,62.1 C114.5,63.7 101.4,64.5 96.3,67.4 C93.5,69 94,70.6 94.6,72.8 C94.9,73.8 95.1,74.7 95.1,75.7 C95,86.1 58.5,82.5 42.4,87.2 C36.7,88.9 37.1,90.8 37.8,93.5 C38.1,94.8 38.4,96.2 38,97.6 C36.3,103.5 21.2,104.3 6.5,106.2 C6.2,105.6 6,105.1 5.8,104.5 C19.8,102.6 35,101.6 36.4,97 C37.4,93.7 31.8,88.4 42,85.4 C56.7,81.1 93.4,83.4 93.4,75.6 C93.4,72.9 90.1,68.7 95.5,65.7 C101,62.6 114.1,61.8 126.4,60.2 C141.8,58.2 144.7,55.6 147.4,52.6 C147.6,53.2 147.8,53.9 148,54.5 C145.2,57.5 141.4,60.1 126.5,62.1 Z"></path>{" "}
              <path d="M103.7,79.2 C103.8,80.2 103.9,81.2 103.7,82.3 C101.6,93.2 53.5,88.3 46.9,95.5 C45.9,96.6 46.3,97.9 46.9,99.8 C47.6,101.7 48.4,104 46.5,106 C42.1,110.7 24,111.1 9.8,113 C9.5,112.5 9.2,111.9 8.9,111.3 C23.2,109.3 41.4,108.9 45.2,104.8 C47.6,102.2 42.3,97.9 45.5,94.3 C52.5,86.5 100.2,90.8 101.9,82 C102.6,78.3 98,73.2 112.6,69.9 C125.5,67 139.5,67.1 149.6,62 C149.7,62.6 149.8,63.2 149.9,63.8 C143.2,67 135.1,68.1 126.6,69.3 C104.8,72.3 103.1,74.2 103.7,79.2 Z"></path>{" "}
              <path d="M112.6,85.9 C112.3,88 112,90.4 108.1,92.3 C96.8,97.7 59.3,95.7 55.6,102.2 C55,103.3 55.5,104.6 56.1,106.1 C56.8,107.8 57.5,109.7 56.5,111.4 C55.5,113.3 52.8,114.6 48.1,115.6 C38.3,117.7 24.3,117.9 13.8,119.5 C13.4,119 13.1,118.5 12.7,117.9 C28.5,115.2 52,115.9 54.9,110.6 C55.5,109.5 55,108.2 54.4,106.7 C53.7,105 52.9,103.1 54,101.2 C58.4,93.4 95.8,96.1 107.2,90.6 C113.5,87.5 107.9,82.9 115.2,78.9 C122.7,74.9 138.8,74.2 150.7,70 C150.7,70.6 150.8,71.2 150.8,71.8 C145.1,73.8 138.6,75 132.7,76.1 C115.3,79.4 113.3,81 112.6,85.9 Z"></path>{" "}
              <path d="M90,102.8 C63.7,105.4 63.5,107.4 65.4,112.4 C66.8,116.3 66.6,118.9 61.4,120.7 C56.1,122.6 45.1,123.5 34.2,124.4 C28.4,124.9 23.2,125.3 18.9,125.9 C18.5,125.4 18,124.9 17.6,124.3 C21.8,123.5 27.2,123.1 34.1,122.5 C39.3,122.1 45.3,121.6 51.2,120.9 C54.4,120.5 62.9,119.4 64.2,116.6 C65.3,114.3 61.7,110.8 63.4,107.5 C67.7,99.6 104.7,102.2 115.6,96.1 C122.4,92.3 115.7,87.5 133.8,82.8 C136,82.2 138.2,81.7 140.2,81.2 C143.8,80.4 147.3,79.5 151,78.3 C151,78.9 151,79.4 150.9,80 C150.9,80.1 150.9,80.1 150.9,80.2 C145.3,82 140.1,82.9 134.2,84.5 C108,91.5 137.4,98.1 90,102.8 Z"></path>{" "}
              <path d="M98.2,109 C90.1,110 81.8,110.9 77.4,112.8 C70.6,115.8 76.9,119.6 74.4,123.8 C73.4,125.4 71.3,126.6 67.7,127.5 C57.4,130.1 36.8,130.1 25.4,132.4 C24.9,131.9 24.3,131.4 23.8,130.9 C29.1,129.7 36.6,129.1 45.2,128.4 C57.9,127.4 70.7,126.4 72.8,122.8 C73.4,121.8 73.1,120.5 72.7,119.1 C70.7,111.2 76.1,109.7 97.9,107.1 C140.7,102 113.4,98.4 146.3,89 C147.8,88.6 149,88.2 150.1,87.9 C150,88.5 149.9,89.2 149.8,89.8 C148.9,90.1 148,90.3 146.9,90.6 C114.4,100.1 141.9,103.8 98.2,109 Z"></path>{" "}
              <path d="M53.6,134.6 C61.2,134 68.4,133.4 73.8,132.3 C82.2,130.5 82,128.2 81.8,125.4 C81.2,118 86.7,116.5 105,113.7 C139.7,108.5 125.6,105.6 147.6,98.8 C147.4,99.5 147.1,100.2 146.9,100.9 C128.7,107 136.2,110.4 112.4,114.3 C99.8,116.4 86.1,117.5 83.9,121.9 C82.2,125.4 87.8,131 74.2,133.9 C68.6,135.1 61.4,135.6 53.7,136.2 C49.3,136.6 39.9,137.1 33.3,138.6 C32.6,138.2 32,137.7 31.4,137.2 C36.8,135.9 44.8,135.3 53.6,134.6 Z"></path>{" "}
              <path d="M61.4,140.8 C87.5,138.6 89.7,136.8 90.7,131.6 C91,130 91.3,128.2 93.3,126.5 C99.6,121 119.2,120.7 131.3,115.7 C137.2,113.3 137.9,112 143.2,110 C142.8,110.8 142.4,111.6 141.9,112.5 C140.7,113.1 139.4,113.7 138,114.4 C136.2,115.4 134.3,116.4 132,117.4 C119.8,122.4 100.2,122.9 94.5,127.9 C91.5,130.6 93.6,133.9 90,137 C85.7,140.6 74,141.6 61.6,142.7 C55.2,143.2 48.7,143.8 43.8,144.7 C42.9,144.3 42.1,143.9 41.2,143.4 C45.6,142.3 52.2,141.6 61.4,140.8 Z"></path>{" "}
              <path d="M63.3 147.6C71.8 146.6 82.2 145.9 89 144.3 96.5 142.6 97.8 140.3 99.2 137.9 104.8 127.9 119.2 129.7 135.3 122.5 134.5 123.6 133.6 124.6 132.8 125.6 130.1 126.6 126.7 127.7 121.9 128.9 111.9 131.4 105.3 132.9 102.3 136.5 99.6 139.7 100.2 143.6 89.5 146.1 82.5 147.8 72.1 148.5 63.6 149.5 62.1 149.7 60.7 149.8 59.4 150 58 149.7 56.6 149.3 55.2 148.9 57.4 148.3 60.1 148 63.3 147.6zM120.2 137.1C115.8 140.3 111 143.1 105.9 145.3 108.2 143 109.7 140.6 120.2 137.1z"></path>
            </g>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default FooterFlourish
